import React from 'react';

export const LongArrowIcon = () => {
  return (
    <svg
      width='8'
      height='2001'
      viewBox='0 0 8 2001'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='long-vertical-arrow'
    >
      <path
        d='M3.64653 2000.35C3.8418 2000.55 4.15838 2000.55 4.35364 2000.35L7.53562 1997.17C7.73088 1996.98 7.73088 1996.66 7.53562 1996.46C7.34036 1996.27 7.02378 1996.27 6.82851 1996.46L4.00009 1999.29L1.17166 1996.46C0.976398 1996.27 0.659815 1996.27 0.464553 1996.46C0.269291 1996.66 0.269291 1996.98 0.464553 1997.17L3.64653 2000.35ZM3.5 2.18556e-08L3.50009 2000L4.50009 2000L4.5 -2.18556e-08L3.5 2.18556e-08Z'
        fill='#151515'
      />
    </svg>
  );
};
