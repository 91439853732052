export const throttle = (function_, timeFrame) => {
  let lastTime = 0;
  return (...arguments_) => {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      function_(...arguments_);
      lastTime = now;
    }
  };
};
